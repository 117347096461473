<template>
    <div class="main-contents info_mf code">
        <div class="tit">상세코드 등록/수정<img src="/images/admin/list_shortcut.png" @click="movePage('back')"/></div>
        <div class="sub-tit"><p>상세코드 등록/수정</p></div>
        <div class="enter_inform">
            <div class="form">
                <div class="label">업무코드</div>
                <div class="list">
                    <SelectComp  :list="loadDetaList" codeKey="cdId" nameKey="cdIdNm" v-model="codeDetail.cdId" :rules="{'required':true}" title="업무코드" :disabled="detaSeq != ''"/>
                </div>
            </div>
            <div class="form">
                <div class="label">정렬순서</div>
                <div class="list">
                    <InputComp v-model="codeDetail.sortOrd" title="정렬순서" :rules="{'required':true}" maxByte="3" min="1"/>
                </div>
            </div>
            <div class="form">
                <div class="label">상세코드</div>
                <div class="list">
                    <InputComp v-model="codeDetail.cd" title="상세코드" :rules="{'required':true}" maxByte="6" :disabled="detaSeq != ''"/>
                </div>
            </div>
            <div class="form">
                <div class="label">상세코드명</div>
                <div class="list">
                    <InputComp v-model="codeDetail.cdNm" title="상세코드명" :rules="{'required':true}" maxByte="100"/>
                </div>
            </div>
            <div class="form">
                <div class="label">상세코드 설명</div>
                <div class="list">
                    <InputComp v-model="codeDetail.cdDesc" maxByte="300"/>
                </div>
            </div>
            <div class="form">
                <div class="label">사용여부</div>
                <div class="list">
                    <SelectComp list="Y:예,N:아니오" title="사용여부" v-model="codeDetail.useYn" :rules="{'required':true}"/>
                </div>
            </div>
        </div>
        <div class="select_btn">
            <div class="btn_01" @click="movePage('back')">취소</div>
            <div class="btn_02" v-if="detaSeq == ''" @click="setInsertDetailCodeInfo()">등록</div>
            <div class="btn_02" style="background-color: #FF681D;" v-else @click="setUpdateDetailCodeInfo()" >수정</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            codeDetail: {
                cdNm: '',
                cdDesc: '',
                useYn: '',
                sortOrd: '',
                cdIdNm: '',
            },

            detaSeq : this.$route.params.detaSeq || '',
            detaSubSeq : this.$route.params.detaSubSeq || '',

            loadDetaList : [],
        }
    },

    beforeMount() {
        this.loadList();
        // this.getBusinessInfo();
        if(this.detaSeq != '') this.getBusinessInfo();
	},

    methods: {

        getBusinessInfo() {
            var param = {};
			param.detaSeq = this.detaSeq;
            param.detaSubSeq = this.detaSubSeq;

			this.$.httpPost('/api/main/adm/code/getDetailCodeInfo', {detaSeq : this.detaSeq, detaSubSeq : this.detaSubSeq})
				.then(res => {
                    
                    // console.log('getDetailCodeInfo RESULT', res);

					this.codeDetail = res.data.detaInfo || {};
				}).catch(this.$.httpErrorCommon);
        },

        movePage(div) {
        if(div == 'back') this.$router.go(-1);
        else this.$router.push('MAN931M03');
        },    
        

        setInsertDetailCodeInfo() {
            this.$.popup('/adm/man/MAN931P02')
				.then(res => {
					if(res) {
						this.$.httpPost('/api/main/adm/code/setInsertDetailCodeInfo', this.codeDetail)
							.then(() => {
								this.movePage();
							}).catch(this.$.httpErrorCommon);
					}
				})
        },

        setUpdateDetailCodeInfo() {
            this.$.popup('/adm/man/MAN931P02')
				.then(res => {
					if(res) {
						this.$.httpPost('/api/main/adm/code/setUpdateDetailCodeInfo', this.codeDetail)
							.then(() => {
								this.movePage();
							}).catch(this.$.httpErrorCommon);
					}
				})
        },

        loadList() {
        this.$.httpPost('/api/main/adm/code/loadDetaList')
            .then(res => {
                // console.log('loadDetaList RESULT', res);
                // console.log(22222);
                // console.log(res);
                this.loadDetaList = res.data.loadDetaList;
            }).catch(this.$.httpErrorCommon);
        },

        
    },
}
</script>